<script>
import Multiselect from "@vueform/multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { reqMethods } from '../../../state/helpers';
import Swal from "sweetalert2";
import { VueFileAgent } from '@boindil/vue-file-agent-next';
import '@boindil/vue-file-agent-next/dist/vue-file-agent-next.css';
import { LMap, LMarker, LTileLayer, LIcon } from "@vue-leaflet/vue-leaflet";
import { latLng } from "leaflet";

/**
 * Projects-create component
 */

export default {
  components: {
    Layout,
    VueFileAgent,
    PageHeader,
    Multiselect,
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  },

  data() {
    return {
      zoom: 12,
      maxZoom: 25,
      center: latLng(6.2012158618, 1.15410990516),
      url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      pageTitle: "Modifier une nouvelle alerte",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Nouvelle alerte",
          active: true,
        },
      ],
      niveaux: [
        { value: '0', label: 'Normal' },
        { value: '1', label: 'Urgent' },
      ],
      isRecording: false,
      mediaRecorder: null,
      chunks: [],
      audioURL: null,
      recordedAudios: [],
      options: [],
      zones: [],
      data: {
        thematic: 1,
        title: null,
        urgency_level: null,
        zones: [],
        coordinates: [],
        messages: [],
        photos: [],
        videos: [],
        audios: [],
        files: [],
      },
      filesData: [],
      spanView: false,
      reqError: null,
      response: null,
    };
  },
  methods: {
    ...reqMethods,
    publishAlert() {
      this.spanView = true;
      ((this.filesData.length !== 0) ? this.filterFiles() : null);
      const that = this;
      this.putRequest({
        route: '/alert/alerts-agent/',
        data: this.data
      }).then(
        function (response) {
          that.spanView = false;
          Swal.fire(response.data.message, "L'Alerte à été Modifié!", "success");
          that.$router.push('/alert/list-alert');
        },
        function (error) {
          that.spanView = false;
          that.reqError = error;
          Swal.fire({
            title: "Oops...",
            text: "Erreur lors de la modification de l'alerte",
            icon: "error",
            confirmButtonClass: "btn btn-primary w-xs mt-2",
            buttonsStyling: false,
            showCloseButton: true,
          });
        }
      )
    },
    filterFiles() {
      this.filesData.forEach(element => {
        if (element.file.type.includes('image/')) {
          this.data.photos.push(element.file);
        } else if (element.file.type == 'application/pdf') {
          this.data.files.push(element.file);
        } else if (element.file.type.includes('video/')) {
          this.data.videos.push(element.file);
        }
      })
    },
    getThematics() {
      const that = this;
      this.getRequest('/alert/thematics/')
        .then(
          function (response) {
            response.data.forEach(element => {
              if (element.for_centers == true) {
                that.options.push({ value: element.id, label: element.name });
              }
            });
          },
          function (error) {
            that.fetchError = error;
          }
        )
    },
    getZones() {
      const that = this;
      this.getRequest('/account/zones/')
        .then(
          function (response) {
            response.data.forEach(element => {
              that.zones.push({ value: element.id, label: element.name });
            });
          },
          function (error) {
            that.fetchError = error;
          }
        )
    },
    getAlertDetails() {
      const that = this;
      const id = { id: parseInt(this.$route.params.id) };
      this.postRequest({
        route: '/alert-agent/findbyid/',
        data: id,
      })
        .then(
          function (response) {
            that.data = response.data;
          },
          function (error) {
            that.reqError = error;
          }
        )
    },
    async startRecording() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.addEventListener('dataavailable', this.onDataAvailable);
        this.mediaRecorder.addEventListener('stop', this.onRecordingStopped);
        this.chunks = [];
        this.mediaRecorder.start();
        this.isRecording = true;
      } catch (error) {
        console.error('Erreur lors de la demande d\'accès à l\'audio:', error);
      }
    },
    stopRecording() {
      this.mediaRecorder.stop();
      this.isRecording = false;

    },
    onDataAvailable(event) {
      event.data.size > 0 ? this.chunks.push(event.data) : null;
    },
    onRecordingStopped() {
      const audioBlob = new Blob(this.chunks, { type: 'audio/wav' });
      const audioURL = URL.createObjectURL(audioBlob);
      this.data.audios.push(audioURL);
    },
    deleteAudio(index) {
      this.data.audios.splice(index, 1);
    },
    handleMapClick(event) {
      const { lat, lng } = event.latlng;
      this.alert.coordinates.push([lat, lng]);
    },

  },
  mounted() {
    !navigator.mediaDevices.getUserMedia ? alert('Votre navigateur ne supporte pas l\'enregistrement audio.') : null;
    this.getThematics();
    this.getZones();
    this.getAlertDetails();
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="pageTitle" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Thématique</label>
                <div class="col-lg-10">
                  <multiselect class="test-primary" v-model="data.thematic" :options="options"></multiselect>
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Titre</label>
                <div class="col-lg-10">
                  <input id="title" name="title" v-model="data.title" type="text" class="form-control"
                    placeholder="Saisir le titre de l'alerte..." />
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Niveau d'alerte</label>
                <div class="col-lg-10">
                  <multiselect class="test-primary" v-model="data.urgency_level" :options="niveaux"></multiselect>
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Zone de couverture</label>
                <div class="col-lg-10">
                  <multiselect class="test-primary" v-model="data.cover_area" :options="zones"></multiselect>
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Description</label>
                <div class="col-lg-10">
                  <textarea id="projectdesc" v-model="data.description" class="form-control" rows="4"
                    placeholder="Donnez une desciption détaillée de l'alerte..."></textarea>
                </div>
              </div>

              <div class="form-group row mb-4">
                <label class="col-form-label col-lg-2">Enregistrer audio</label>
                <div class="col-lg-10">
                  <div class="audio-recorder">
                    <div class="recorder-controls">
                      <button type="button" @click="startRecording" :disabled="isRecording"
                        :class="{ 'record-button': true, 'pulse': isRecording }">
                        <span v-if="!isRecording"><i class="mdi mdi-microphone-outline"></i></span>
                        <span v-else><i class="mdi mdi-microphone-settings"></i></span>
                      </button>
                      <button type="button" @click="stopRecording" :disabled="!isRecording" class="stop-button"><i
                          class="mdi mdi-stop"></i>
                      </button>
                    </div>
                    <div class="audio-player" v-if="data.audios.length > 0">
                      <div class="audio-item" v-for="(audio, index) in data.audios" :key="index">
                        <audio :src="audio" controls></audio>
                        <button type="button" @click="deleteAudio(index)" class="delete-button"><i
                            class="mdi mdi-close"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row mb-4">
                <label class="col-form-label col-lg-2">Fichiers associés</label>
                <div class="col-lg-10">
                  <VueFileAgent :deletable="true" :maxSize="'50MB'" :maxFiles="20" :meta="true" :multiple="true"
                    :uploadUrl="uploadUrl" v-model="filesData" />
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Coordonnées géographique</label>
                <div class="col-lg-10">
                  <l-map :zoom="zoom" style="height: 500px;" :center="center" @click="handleMapClick">
                    <l-tile-layer :url="url" :maxZoom="maxZoom" />
                    <l-marker v-for="marker in alert.coordinates" :key="marker.id" :visible="marker.visible"
                      :draggable="marker.draggable" :lat-lng="marker.position" @click="position(marker)">
                      <l-icon :icon-anchor="staticAnchor">
                        <img src="@/assets/images/leaflet/marker-icon.png" />
                      </l-icon>
                    </l-marker>
                  </l-map>
                </div>
              </div>

              <div class="row justify-content-end">
                <div class="col-md-10">
                  <button type="button" @click="publishAlert()" class="btn btn-success" style="float: right"
                    :disabled="spanView">
                    <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                    Modifier
                  </button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.recorder-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pulse:before {
  content: '';
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  background-color: inherit;
  border-radius: inherit;
  transition: opacity .3s, transform .3s;
  animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
  z-index: 1;
}

@keyframes pulse-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

.record-button {
  background-color: #28a745;
  position: absolute;
  color: white;
  border: none;
  border-radius: 90px;
  padding: 8px 16px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  top: 0;
  right: -50;
}

.stop-button {
  background-color: #dc3545;
  position: absolute;
  color: white;
  border: none;
  border-radius: 90px;
  padding: 8px 16px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;
}

.audio-player {
  width: 100%;
}

.audio-item {
  display: flex;
  justify-content: center;
  margin-top: 7px;
}

.delete-button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
}
</style>


